/*
 * @Author: zy 848175192@qqcom
 * @Date: 2023-02-02 15:20:03
 * @LastEditors: zy 848175192@qqcom
 * @LastEditTime: 2023-02-02 15:39:27
 * @FilePath: \guzhengbaohee:\shenYuan\gzbhwebsite\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'windi.css'
import './assets/css/public.css'
Vue.config.productionTip = false;
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
