<!--
 * @Author: zy 848175192@qqcom
 * @Date: 2023-02-02 15:20:03
 * @LastEditors: zy 848175192@qqcom
 * @LastEditTime: 2023-02-03 15:19:11
 * @FilePath: \guzhengbaohee:\shenYuan\gzbhwebsite\src\views\HomeView.vue
-->
<template>
  <div>
    <main>
      <!-- <section class="banner">
        <ul>
          <li><img :src="require('@/assets/img/banner_01.jpg')" alt="banner"></li>
          <li><img :src="require('@/assets/img/banner_02.jpg')" alt="banner"></li>
          <li><img :src="require('@/assets/img/banner_03.jpg')" alt="banner"></li>
        </ul>
        <div class="prev"><img :src="require('@/assets/img/index-xb2.png')" alt=""></div>
        <div class="next"><img :src="require('@/assets/img/index-xb1.png')" alt="next"></div>
        <div class="count">
          <ul>
            <li></li>
            <li class="active"></li>
            <li></li>
            <li></li>
          </ul>
        </div>
      </section> -->
      <div>
        <el-carousel trigger="click" height="344px">
          <el-carousel-item>
            <img :src="require('@/assets/img/01.jpg')" alt="">
          </el-carousel-item>
          <el-carousel-item>
            <img :src="require('@/assets/img/02.jpg')" alt="">
          </el-carousel-item>
        </el-carousel>
      </div>
      <section class="about">
        <h1 class="title">WELCOME TO GUZHENGBAOHE</h1>
        <p class="content">
          固正保和创立于1994年，，⽬前是⼀家以“中医体质调理”为 核⼼业务的专业中医健康管理机构。 集合国内外知名院校、医疗机构、中医专家、⼤数据专家
          等，打造“数字化、标准化、智能化、⽣活化”的数字中医健康管理体系，构建集“中医医学研究
          院科研平台、数字中医健康管理平台、数字中医社区健康管理中⼼、中医药智慧康养基地”等为⼀体的“医+康+养”中医健康产业集群，致⼒成为中国中医健康管理的引领者。
        </p>
        <article>
          <div class="box">
            <img :src="require('@/assets/img/11.png')" class="w-[44px] h-[44px]">
            <h2>CULTURAL <div>企业文化</div>
            </h2>
            <p>优秀的企业文化能给企业注入蓬勃的活力，是企业的灵魂，固正保和十分注重自身企业的文化建设，将企业文化的精髓传递给每一位员工。</p>
          </div>
          <div class="box">
            <img :src="require('@/assets/img/12.png')" class="w-[44px] h-[44px]">
            <h2>HONOUR <div>企业荣誉</div>
            </h2>
            <p>固正保和热⼼公益，是四川索玛慈善基⾦会理事单位，并⻓期关注⼤凉⼭孩⼦的学习和教育问题。秉承社会担当，践⾏公益温暖。固正保和保持公益初⼼，让公益之路贯穿企业发展史，切实为公益 贡献⼀份绵薄之⼒，献上⼀份关爱。
            </p>
          </div>
          <div class="box">
            <img :src="require('@/assets/img/13.png')" class="w-[44px] h-[44px]">
            <h2>QUALIFICATION <div>资格认证</div>
            </h2>
            <p>2021年初，固正保和正式被授予李佃贵“国医⼤师传承⼯作室”。国医⼤师传承⼯作 室的设⽴将为固正保和培育更多中医精英⼈才，为客⼾提供更优质的中医健康管理服务，推动固正保和在中医健康管理⾏业⻓⾜发展。</p>
          </div>
          <div class="box">
            <img :src="require('@/assets/img/14.png')" class="w-[44px] h-[44px]">
            <h2>RESEARCH <div>研究开发</div>
            </h2>
            <p>固正保和遵循中医“治未病”理念，结合现代科技，以中医九种 体质理论为基础，创造性的研发出了“九种体质九⼤调理技术”。该技术中的核⼼产品和关键
              技法已经申请了多项国家发明专利和著作权，满⾜了⼤众“辩体调体、科学养⽣、个性调理”的需求。</p>
          </div>
        </article>
      </section>
      <section class="production">
        <h1 class="title">PRODUCTION CENTER</h1>
        <p class="content">
          通过互联网和物联网为健康领域赋能，以中医体质健康管理为切入点，提供个性化健康管理的产品和服务体系，创建中医智慧健康新生活。</p>
        <div class="images flex justify-center">
          <ul class="flex justify-center">
            <li v-for="(item, index) in proList" :key="index">
              <img :src="item.img">
              <div class="light">{{ item.text }}<div class="jia"></div>
              </div>
            </li>
          </ul>
          <!-- <div class="next"><img :src="require('@/assets/img/index-xb1.png')" alt="next"></div> -->
        </div>
      </section>
      <section class="news">
        <h1 class="title">NEWS CENTER</h1>
        <p class="content">固正保和数年来培育了数千万忠实用户，特别是得到了中国广大中老年客户的喜爱与认可</p>
        <article>
          <div class="box1">
            <img :src="require('@/assets/img/03.jpg')">
            <div>尤虎博士、王聚和先生荣获健康推广使者称号</div>
          </div>
          <div class="box2">
            <div class="hot">
              <div class="date">
                <div class="day">16</div>
                <div class="year">2021-12</div>
              </div>
              <div class="text">
                <h3>尤虎博士、王聚和先生荣获健康推广使者称号</h3>
                <p>
                  2021年12月，健康中国基层重要发展论坛，固正保和中医医学研究院院长尤虎博士，执行院长王聚和荣获健康推广使者账号
                </p>
              </div>
            </div>
            <ul>
              <li v-for="(item, index) in news" :key="index" class="flex justify-between items-center"
                :class="index == 0 ? 'first' : ''">
                <div class=" w-[90%] truncate">{{ item.text }}</div>
                <time>{{ item.date }}</time>
              </li>
            </ul>
          </div>
        </article>
      </section>
      <section class="video">
        <h1 class="title">VIDEO CENTER</h1>
        <p class="content">弘扬数字中医，幸福千万家庭。为顺应国家“健康中国”战略，落实“把以治病为中⼼转变 为以⼈⺠健康为中⼼”的新主旨，发挥中医药在养⽣、治未病、药⻝同源、健康服务等⽅⾯的独特优势，固正保和应运⽽⽣。</p>
        <ul>
          <li v-for="(item,index) in videoList" :key="index">
            <img :src="item.img" class="w-[260px] h-[195px]">
            <!-- <div>{{ item.title }}</div>
            <p>{{ item.text }}</p> -->
          </li>
        </ul>
      </section>
    </main>
    
  </div>
</template>
<script>
export default {
  data() {
    return {
      
      proList: [
        {
          img: require('@/assets/img/04 九体草本膏.jpg'),
          text: '九体草本膏'
        },
        {
          img: require('@/assets/img/05 十二时辰茶.jpg'),
          text: '十二时辰茶'
        },
        {
          img: require('@/assets/img/06 茯苓冬瓜药膳包.jpg'),
          text: '茯苓冬瓜药膳包'
        },
        {
          img: require('@/assets/img/07 伊养纤喜.jpg'),
          text: '伊养纤喜'
        },
      ],
      news: [
        {
          text: '荣获消费日报“2021年度中医药大健康消费推荐品牌”',
          date: '2021.11'
        },
        {
          text: '被认证为“治未病服务适宜技术体质调理培训单位”，推广中医治未病服务',
          date: '2021.11'
        },
        {
          text: '固正保和荣获“2020年度推动中医药发展杰出贡献 奖”和“2020年度中国科技创新发明优秀成果”双重奖项',
          date: '2020.09'
        },
        {
          text: '成都固正保和中医医学研究院成立',
          date: '2019.12'
        },
        {
          text: '固正保和成为“全国卫生产业企业管理协会治未病分会常务理事单位”',
          date: '2019.06'
        },
      ],
      videoList:[
        {
          img:require('@/assets/img/15.jpg'),
          title:'享受生活,从饮水开始！',
          text:'双层过滤滤芯，使饮水更加安全、放心。'
        },
        {
          img:require('@/assets/img/16.jpg'),
          title:'享受生活,从饮水开始！',
          text:'双层过滤滤芯，使饮水更加安全、放心。'
        },
        {
          img:require('@/assets/img/17.jpg'),
          title:'享受生活,从饮水开始！',
          text:'双层过滤滤芯，使饮水更加安全、放心。'
        },
        {
          img:require('@/assets/img/18.jpg'),
          title:'享受生活,从饮水开始！',
          text:'双层过滤滤芯，使饮水更加安全、放心。'
        },
      ]
    }
  },
  methods:{
    
  }
}
</script>
<style lang="scss" scoped>
:deep(.el-carousel__indicator.el-carousel__indicator--horizontal) {
  button {
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }
}

:deep(.el-carousel__indicator.el-carousel__indicator--horizontal.is-active) {
  button {
    background: #66cdaa !important;
  }
}



.banner ul {
  width: 10000px;
}

.banner li {
  float: left;
}

.banner .prev,
.next {
  position: absolute;
  top: 50%;
  margin-top: -16px;
}

.banner .prev {
  left: 50px;
}

.banner .prev:hover {
  content: url("@/assets/img/index-xb4.png");
}

.banner .next:hover {
  content: url("@/assets/img/index-xb3.png");
}

.banner .next {
  right: 50px;
}

.count {
  width: 100%;
  height: 10px;
  position: absolute;
  bottom: 25px;
}

.count ul {
  width: 80px;
  margin: 0 auto;
}

.count ul li {
  width: 10px;
  height: 10px;
  cursor: pointer;
  background-color: #666;
  opacity: .5;
  float: left;
  margin-right: 10px;
  border-radius: 50%;
}

.count .active {
  background-color: #66cdaa;
  opacity: .8;
}

section {
  margin-bottom: 20px;
  display: block;
  text-align: center;
}

section .title {
  margin-bottom: 30px;
}

section p {
  font-size: 14px;
  color: #666666;
}

article {
  display: flex;
}

.about {
  width: 1200px;
  margin: 0 auto;
  margin-bottom: 30px;
}

.content {
  width: 1020px;
  margin: 0 auto;
}

.about h1 {
  line-height: 40px;
}

.title:after {
  content: "";
  background-color: #66cdaa;
  display: block;
  width: 40px;
  height: 4px;
  margin: 0 auto;
}

.about .box {
  width: 21%;
  float: left;
}

.about .box {
  margin: 20px;
}

.about .box h2 {
  margin-bottom: 30px;
}

.about .box div {
  color: #666666;
  font-size: 16px;
}

.about .box img {
  margin-left: 100px;
  margin-bottom: 50px;
  margin-top: 50px;
}

.production {
  width: 100%;
  margin: 0 auto;
}

.production p {
  font-size: 14px;
  color: #666666;
  margin: 0 auto;
}

.images {
  width: 100%;
  position: relative;
  margin-top: 50px;
  margin-bottom: 70px;
  overflow: hidden;
}

.images ul {
  width: 200%;

}

.images li {
  width: 318px;
  float: left;
  line-height: 30px;
  background-color: #e5e5e5;
}

.images li:hover {
  background-color: #66cdaa;
  color: #ffffff;
}

.images li:hover :last-child :only-child {
  background: url('@/assets/img/iconfont-jiahao2.png') no-repeat;
}

.images li div {
  float: left;
  margin-left: 20px;
}

.images .jia {
  float: left;
  position: relative;
  top: 10px;
  right: -260px;
}

.jia {
  width: 14px;
  height: 14px;
  cursor: pointer;
  background: url('@/assets/img/iconfont-jiahao.png') no-repeat;
}


.images .next {
  position: absolute;
  top: 50%;
  right: 250px;
}

.images .next:hover {
  content: url("@/assets/img/index-xb3.png");
}

.news {
  width: 1200px;
  margin: 0 auto;
  margin-bottom: 40px;
}

.news .content {
  margin-bottom: 30px;
}

.box1 {
  position: relative;
  width: 50%;
  height: 300px;
}

.box1 img {
  height: 292px;
  margin-left: 28px;
}

.box1 div {
  font-size: 14px;
  position: absolute;
  left: 28px;
  bottom: 8px;
  color: #ffffff;
  width: 525px;
  height: 40px;
  line-height: 40px;
  background-color: #66cdaa;
}

.box2 {
  width: 50%;
}

.box2 .hot {
  overflow: hidden;
  height: 85px;
  margin-bottom: 2px;
}

.box2 .date {
  width: 10%;
  height: 100px;
  float: left;
}

.box2 .date .day {
  font-size: 25px;
  padding: 10px;
  border: 1px #66cdaa solid;
  font-weight: bolder;
  color: #66cdaa;
}

.box2 .date .year {
  height: 20px;
  line-height: 20px;
  font-size: 12px;
  background-color: #66cdaa;
  color: #ffffff;
}

.text {
  width: 50%;
  float: left;
  margin-left: 10px;
  text-align: left;
}

.text p {
  width: 550px;
  text-align: left;
  padding-top: 5px;
  font-size: 10px;
  color: #949494;
  -webkit-transform-origin-x: 0;
  transform: scale(0.89);
}

.text h3 a {
  color: #66cdaa;
}

.box2 ul {
  width: 50%;
  position: relative;
}

.box2 .first {
  border-top: 1px #9f9f9f dashed;
}

.box2 li {
  width: 549px;
  text-align: left;
  line-height: 33px;
  border-bottom: 1px #9f9f9f dashed;
}

.box2 a {
  color: #666666;
}

.box2 li time {
  position: absolute;
  right: -250px;
}

.video {
  width: 1200px;
  margin: 0 auto;
  margin-bottom: 40px;
}

.video ul {
  margin-top: 40px;
  display: flex;
  position: relative;
}

.video ul li {
  margin-left: 30px;
  float: left;

}

.video ul li p {
  color: #949494;
}

.video .prev:hover {
  content: url("@/assets/img/index-xb4.png");
}

.video .next:hover {
  content: url("@/assets/img/index-xb3.png");
}

.video ul li:hover {
  color: #66cdaa;
  box-shadow: 0px 0px 10px rgba(102, 205, 170,0.8)
}

.video .next {
  position: absolute;
  top: 51%;
  right: -20px;
}

.video .prev {
  position: absolute;
  top: 42%;
  left: -20px;
}

.icon {
  font-size: 40px;
}
</style>
