<template>
  <footer>
    <div class="footer-main">
      <div class="footer-nav">
        <dl>
          <dt>关于我们</dt>
          <dd @click="$router.push('/about?type=1')">公司简介</dd>
          <dd @click="$router.push('/about?type=2')">企业文化</dd>
          <dd @click="$router.push('/about?type=3')">企业荣誉</dd>
          <dd @click="$router.push('/about?type=4')">资格认证</dd>
        </dl>
        <dl>
          <dt>产品中心</dt>
          <dd @click="$router.push('/product')">产品中心</dd>
        </dl>
        <dl>
          <dt>新闻中心</dt>
          <dd @click="$router.push('/news?type=1')">最新动态</dd>
          <dd @click="$router.push('/news?type=2')">企业新闻</dd>
          <dd @click="$router.push('/news?type=3')">行业新闻</dd>
        </dl>
        <dl>
          <dt>联系我们</dt>
          <dd @click="$router.push('/contact')">联系我们</dd>
        </dl>
      </div>
    </div>
    <div
      class="footer-footer w-full flex flex-col items-center justify-center flex-1"
    >
      <div class="flex">
        <img :src="require('@/assets/img/beian.png')" class="w-5 h-5" />
        <div class="flex flex-col items-start">
          <a
            class="hover_line"
            target="_blank"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51010702003345"
          >
            <p>川公网安备 51010702003345号</p>
          </a>
          <a
            class="hover_line"
            target="_blank"
            href="https://beian.miit.gov.cn"
          >
            <p>蜀ICP备 2023027975号-1</p>
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      list: [[{}]],
    };
  },
  watch: {
    $route(newData, oldData) {
      if (newData) {
        // let dom=document.getElementById('app')
        document.documentElement.scrollTop = document.body.scrollTop = 0;
      }
    },
  },
  methods: {
    open() {
      window.open("https://www.miit.gov.cn/", "_blank");
    },
  },
};
</script>
<style lang="scss" scoped>
footer {
  width: 100%;
  height: 360px;
  background-color: #66cdaa;
  margin-top: 150px;
  color: #fff;
  display: flex;
  flex-direction: column;
  .hover_line{
    p{
      color: #fff;
      line-height: 20px;
      margin-left: 5px;
    }
    p:hover{
      text-decoration: underline;
    }
  }
}

.footer-main {
  overflow: hidden;
}

.footer-nav {
  width: 40%;
  display: flex;
  justify-content: space-around;
  margin-left: 300px;
  margin-top: 50px;
  float: left;
}

.footer-nav dl {
  float: left;
  text-align: left;
  margin-left: 50px;
}

.footer-nav dt {
  font-size: 14px;
  line-height: 30px;
  border-bottom: 2px #d9d9d9 solid;
  margin-bottom: 10px;
}

.footer-nav dd {
  font-size: 12px;
  padding: 10px 0;
  cursor: pointer;
}

.footer-nav a {
  color: #d9d9d9;
}

.footer-nav a:hover {
  color: white;
}

footer form {
  width: 400px;
  float: right;
  margin-top: 60px;
  margin-right: 15%;
}

footer form input,
footer form textarea {
  outline: none;
  border: 1px #d9d9d9 solid;
  background-color: #66cdaa;
  color: #d9d9d9;
}

footer form input {
  padding-left: 5px;
  font-size: 12px;
  width: 138px;
  height: 30px;
  margin-left: 20px;
}

footer form textarea {
  padding: 5px;
  width: 300px;
  height: 100px;
  margin-top: 10px;
  margin-left: 20px;
}

footer form input[type="submit"] {
  width: 100px;
  color: #d9d9d9;
  margin-top: 5px;
}

.footer-footer {
  margin-top: 40px;
  border-top: 2px #d9d9d9 solid;
  color: #d9d9d9;
  font-size: 12px;
  line-height: 40px;
  text-align: center;
}
</style>