/*
 * @Author: zy 848175192@qqcom
 * @Date: 2023-02-02 15:20:03
 * @LastEditors: zy 848175192@qqcom
 * @LastEditTime: 2023-02-08 15:22:00
 * @FilePath: \guzhengbaohee:\shenYuan\gzbhwebsite\src\router\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: function () {
      return import('../views/AboutView.vue')
    }
  },
  {
    path: '/news',
    name: 'news',
    component: function () {
      return import('../views/news/index.vue')
    }
  },
  {
    path: '/newsDetail',
    name: 'newsDetail',
    component: function () {
      return import('../views/news/detail.vue')
    }
  },
  {
    path: '/product',
    name: 'product',
    component: function () {
      return import('../views/product/index.vue')
    }
  },
  {
    path: '/contact',
    name: 'contact',
    component: function () {
      return import('../views/contact/index.vue')
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
export default router
