<template>
    <header>
        <div class="header clearBoth">
            <div class="logo">
                <a href="index.html">
                    <img :src="require('@/assets/img/logo2.png')">
                </a>
            </div>
            <div class="nav">
                <ul>
                    <li @click="toNav(item, index)" v-for="(item, index) in navList" :key="index"
                        :class="index == active ? 'active' : ''">{{ item.text }}</li>
                </ul>
            </div>
            <!-- <div class="language">
          <a href="#" class="active">CN</a>
          <a href="#">EN</a>
        </div> -->
        </div>
    </header>
</template>
<script>
export default {
    data() {
        return {
            navList: [
                {
                    text: '企业首页',
                    path: '/'
                },
                {
                    text: '关于我们',
                    path: '/about'
                },
                {
                    text: '新闻中心',
                    path: '/news'
                },
                {
                    text: '产品中心',
                    path: '/product'
                },
                // {
                //     text: '客户服务',
                //     path: '/service'
                // },
                {
                    text: '联系我们',
                    path: '/contact'
                },
            ],
            active: 0
        }
    },
    watch: {
        $route(newData, oldData) {
            if (newData.path != '/') {
                if (this.navList.findIndex(e => e.path!='/'&&newData.path.indexOf(e.path) != -1) != -1) {
                    this.active = this.navList.findIndex(e => e.path!='/'&&newData.path.indexOf(e.path) != -1)
                }else{
                    this.active=0
                }
            }else{
                this.active=0
            }
        }
    },
    methods: {
        toNav(item, index) {
            this.active = index;
            this.$router.push(item.path)
        }
    }
}
</script>
<style lang="scss" scoped>
.header {
    width: 1200px;
    margin: auto;
    display: flex;
    align-items: center;
}

.logo {
    height: 80px;
    float: left;
    display: flex;
    justify-content: center;
    align-items: center;
}

.banner {
    width: 100%;
    overflow: hidden;
    position: relative;
}

.nav {
    width: 69%;
    margin-left: 10%;
    float: left;
}

.nav ul {
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
}

li {
    font-size: 15px;
    /* float: left; */
    margin-right: 70px;
    line-height: 50px;
    text-align: center;
    white-space: nowrap;
    border-bottom-width: 5px;
    border-color: white;
    cursor: pointer;
}

li:hover {
    color: #66cdaa;
    position: relative;
    border-color: #66cdaa;
}

.active {
    color: #66cdaa;
    position: relative;
    border-color: #66cdaa;
}
</style>