<!--
 * @Author: zy 848175192@qqcom
 * @Date: 2023-02-02 15:20:03
 * @LastEditors: zy 848175192@qqcom
 * @LastEditTime: 2023-02-02 17:03:44
 * @FilePath: \guzhengbaohee:\shenYuan\gzbhwebsite\src\App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div id="app">
    <Head />
    <router-view/>
    <Footer />
  </div>
</template>
<script>
import Head from '@/components/head/index.vue'
import Footer from '@/components/footer/index.vue'
export default {
  components:{Head,Footer}
}
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

</style>
